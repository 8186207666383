import closeImage from "../assets/images/svg/close.svg";
import menuImage from "../assets/images/svg/menu.svg";

function handleClickShowNavButton() {
	const showNavButton = document.getElementById("showNavButton");
	const nav = document.getElementById("nav");
	const menuIcon = document.getElementById("menuIcon");

	if (!showNavButton || !nav || !menuIcon) {
		return;
	}

	showNavButton.addEventListener("click", () => {
		document.body.classList.toggle("no-scroll");
		nav.classList.toggle("show");

		if (nav.classList.contains("show")) {
			menuIcon.src = closeImage;
		} else {
			menuIcon.src = menuImage;
		}
	});
}

function inicializar() {
	handleClickShowNavButton();
}

document.addEventListener("DOMContentLoaded", inicializar);

document.querySelectorAll(".whatsappFloating").forEach(
	(element) => {
		element.addEventListener("click", gtag_report_conversion)
	}
)